






































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {authModule} from '@/store/modules';
import {reportModule} from '@/store/modules';
import {Role} from '@/enums';
import {ReportResource} from '@/interfaces';
import PageTitle from '@/components/PageTitle.vue';
import StatCard from '@/components/StatCard.vue';
import {centsToDollarsRounded, minutesToHoursRounded} from '@/filters';

@Component({
  components: {StatCard, PageTitle},
})
export default class Dashboard extends Vue {
  private minutesToHoursRounded = minutesToHoursRounded;
  private centsToDollarsRounded = centsToDollarsRounded;

  get firstName(): string | null {
    return authModule.firstName;
  }

  get role(): Role | null {
    return authModule.role;
  }

  get summaryReport(): ReportResource | null {
    return reportModule.find('summary');
  }

  get loading(): boolean {
    return reportModule.loading;
  }

  async created(): Promise<void> {
    if (this.role === Role.Staff) {
      await reportModule.loadById('summary');
    } else {
      await reportModule.loadById(
        `summary?filter[clientId]=${authModule.clientId}`
      );
    }
  }
}
