













import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class StatCard extends Vue {
  @Prop({default: null}) private amount!: string | null;
  @Prop({default: 'green'}) private color!: string | null;
  @Prop({default: null}) private label!: string | null;
}
