var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-alert',{attrs:{"type":"info","dismissible":""}},[_vm._v(" Welcome "+_vm._s(_vm.firstName)+"! ")]),_c('PageTitle',[_vm._v("Dashboard")]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.loading && _vm.summaryReport)?_c('section',[(_vm.role === 'staff')?_c('div',[_c('h2',{staticClass:"mt-10"},[_vm._v("Summary")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"label":"unbilled total","amount":("$" + (_vm.centsToDollarsRounded(
                _vm.summaryReport.attributes.unbilledTotal
              )))}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"label":"unbilled hours","amount":_vm.minutesToHoursRounded(_vm.summaryReport.attributes.unbilledMinutes)}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"label":"unbilled total per day this month","amount":("$" + (_vm.centsToDollarsRounded(
                _vm.summaryReport.attributes.unbilledTotalPerDayThisMonth
              )))}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":("$" + (_vm.centsToDollarsRounded(
                _vm.summaryReport.attributes.unbilledTotalPerBusinessDayThisMonth
              )))}},[_vm._v(" unbilled total per business day this month"),_c('br'),_c('small',[_vm._v(" (52 weeks - 4 weeks) / 12 months x 5 days per week")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":_vm.minutesToHoursRounded(
                _vm.summaryReport.attributes.unbilledMinutesThisMonth
              ),"label":"unbilled hours this month"}})],1)],1)],1):_vm._e(),(_vm.role === 'staff')?_c('h2',{staticClass:"mt-10"},[_vm._v("Client Stats")]):_vm._e(),_vm._l((_vm.summaryReport.attributes.clientStats),function(stat){return _c('div',{key:stat.client.id},[(_vm.role === 'staff')?_c('h3',[_vm._v(_vm._s(stat.client.name))]):_vm._e(),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":("$" + (_vm.centsToDollarsRounded(stat.unbilledTotal))),"label":"unbilled total"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":_vm.minutesToHoursRounded(stat.unbilledMinutes),"label":"unbilled hours"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":stat.projectCount,"label":"projects with unbilled time"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2"}},[_c('StatCard',{attrs:{"amount":stat.timeEntryCount,"label":"unbilled time entries"}})],1)],1)],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }